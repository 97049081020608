<template>
  <div class="vat-invoice">
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <div class="row">
          <b-dropdown size="sm" right>
            <template slot="button-content">
              <i class="fas fa-cog" style="font-size: 1rem"></i>
              <span class="font-weight-bolder">Thao tác</span>
            </template>
            <b-dropdown-item @click="downloadExcel">
              <span>
                <i class="far fa-file-excel icon-size pr-1"></i>
                Xuất Excel</span
              >
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col>
            <BDropdownCustom
              v-model="apiParams.storeIds"
              :options="filteredStore"
              :placeholder="'cửa hàng'"
              :matchKey="['name']"
              :searchable="true"
              :limit="5"
            />
          </b-col>
          <b-col>
            <div class="date-style">
              <b-input-group>
                <date-picker
                  placeholder="Từ"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="apiParams.fromDate"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Đến"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="apiParams.toDate"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              v-model="apiParams.vatCompanyStatus"
              :options="[
                { id: 1, name: 'Mới' },
                { id: 2, name: 'Đang xử lí' },
                { id: 3, name: 'Đã phát hành' },
                { id: 4, name: 'Đã gửi khách' },
                { id: 5, name: 'Hoàn trả' },
              ]"
              value-field="id"
              text-field="name"
              class="select-style"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >Chọn trạng thái</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
          <b-col>
            <b-input
              size="sm"
              placeholder="Nhập email"
              v-model="apiParams.vatCompanyEmail"
              autocomplete="off"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              size="sm"
              placeholder="Nhập tên công ty/ cá nhân"
              autocomplete="off"
              v-model="apiParams.vatCompanyName"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-input
              size="sm"
              placeholder="Nhập mã hoá đơn"
              autocomplete="off"
              v-model="apiParams.refId"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              size="sm"
              placeholder="Nhập mã số thuế"
              autocomplete="off"
              v-model="apiParams.vatCompanyTaxCode"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              size="sm"
              placeholder="Ghi chú hoá đơn đỏ"
              autocomplete="off"
              v-model="apiParams.vatCompanyNote"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              v-model="apiParams.vatCompanyType"
              :options="[
                { id: 1, name: 'Công ty' },
                { id: 2, name: 'Cá nhân' },
              ]"
              value-field="id"
              text-field="name"
              class="select-style"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >Chọn đối tượng</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col cols="1">
            <b-button
              size="sm"
              variant="primary"
              style="width: 70px"
              @click="onFilter()"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-table
              :items="vatInvoices"
              :fields="vatInvoiceFields"
              :small="true"
              :busy="onLoading"
              responsive
              bordered
              hover
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>
              <template #cell(createdAtBill)="data">
                <router-link
                  :to="{
                    path: '/bills/update-bill',
                    query: { id: data.item.refId },
                    target: '_blank',
                  }"
                >
                  <b>{{ data.item.refId }}</b>
                </router-link>
                <span class="font-weight-bold mb-2 d-block">{{
                  data.item.typeName
                }}</span>
                <span class="font-weight-bold mb-2 d-block">{{
                  data.item.createdAtBill
                }}</span>
                <span v-if="data.item.cashierName" class="mb-2 d-block">
                  <b class="d-block"> KTBH: </b>
                  <span> {{ data.item.cashierName }}</span>
                </span>
              </template>
              <template #cell(storeName)="data">
                <b class="d-block">{{ data.item.storeName }}</b>
              </template>
              <template #cell(vatCompanyName)="data">
                <small class="font-weight-bolder text-primary"
                  >({{ data.item.vatCompanyTypeName }})</small
                >
                <span v-if="data.item.taxCode" class="d-block mb-2">
                  MST:
                  <span class="font-weight-bold text-warning">{{
                    data.item.taxCode
                  }}</span>
                </span>
                <span class="font-weight-bold d-block mb-2">{{
                  data.item.vatCompanyName
                }}</span>
                <span v-if="data.item.vatCompanyAddress">
                  <span class="font-weight-bold">ĐC:</span>
                  <span> {{ data.item.vatCompanyAddress }}</span>
                </span>
              </template>
              <template #cell(totalAmount)="data">
                <b class="d-block text-success">{{
                  convertPrice(data.item.totalAmount)
                }}</b>
              </template>
              <template #cell(vatCompanyCreatedAt)="data">
                <span class="d-block text-primary">{{
                  data.item.vatCompanyCreatedAt
                }}</span>
              </template>
              <template #cell(vatCompanyUpdatedAt)="data">
                <span class="d-block text-primary">{{
                  data.item.vatCompanyUpdatedAt
                }}</span>
                <p class="pb-0 mb-0">{{ data.item.vatCompanyUpdatedByName }}</p>
              </template>
              <template #cell(vatCompanyStatus)="data">
                <span
                  style="width: max-content; margin: 0 10px"
                  v-text="data.item.vatCompanyStatusName"
                  v-bind:class="{
                    'badge badge-warning text-white':
                      data.item.vatCompanyStatus === 1 ||
                      data.item.vatCompanyStatus === null,
                    'badge badge-secondary': data.item.vatCompanyStatus === 2,
                    'badge badge-primary': data.item.vatCompanyStatus === 3,
                    'badge badge-success': data.item.vatCompanyStatus === 4,
                    'badge badge-danger': data.item.vatCompanyStatus === 5,
                  }"
                ></span>
              </template>
              <template #cell(vatCompanyNote)="data">
                <p
                  @click="editVatInvoiceForm(data.item, 'note')"
                  style="cursor: pointer"
                  class="float-right"
                >
                  <i class="fas fa-edit"></i>
                </p>
                <p v-if="data.item.vatCompanyNote" class="pre-line">
                  {{ data.item.vatCompanyNote }}
                </p>
              </template>
              <template #cell(actions)="data">
                <b-dropdown size="sm" no-caret right lazy>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-settings pr-0"
                    ></i>
                  </template>
                  <b-dropdown-item
                    @click="editVatInvoiceForm(data.item, 'status')"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i style="font-size: 1rem" class="flaticon2-pen"></i>
                      &nbsp; Đổi trạng thái
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="uploadVatInvoice(data.item)">
                    <span style="color: #3f4254; font-size: 12px">
                      <i style="font-size: 1rem" class="fas fa-upload"></i>
                      &nbsp; Tải chứng từ
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col>
                <p class="mt-3 text-dark font-weight-bolder">
                  Tổng số :
                  {{ convertPrice(totalItems) }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="customPagination"
                  v-if="numberOfPage >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  align="right"
                  first-class="page-item-first btn btn-icon mr-1 btn-sm my-1"
                  prev-class="page-item-prev btn btn-icon mr-1 btn-sm my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 "
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-1 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
    <b-modal
      id="md-update-vat-invoice"
      hide-footer
      :title="'Cập nhật: #' + vatInvoiceForm.refId"
    >
      <b-row class="mb-5">
        <b-col cols="4" v-if="vatInvoiceFormMode === 'status'">
          <label>Trạng thái</label>
          <b-form-select
            size="sm"
            v-model="vatInvoiceForm.vatCompanyStatus"
            :options="[
              { id: 1, name: 'Mới' },
              { id: 2, name: 'Đang xử lí' },
              { id: 3, name: 'Đã phát hành' },
              { id: 4, name: 'Đã gửi khách' },
              { id: 5, name: 'Hoàn trả' },
            ]"
            value-field="id"
            text-field="name"
            class="select-style"
          >
          </b-form-select>
        </b-col>
        <b-col cols="12" v-if="vatInvoiceFormMode === 'note'">
          <label>Ghi chú hoá đơn đỏ</label>
          <b-form-textarea
            v-model="vatInvoiceForm.vatCompanyNote"
            placeholder="Nhập ghi chú..."
            rows="5"
            max-rows="6"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-button
            style="fontweight: 600; width: 70px"
            variant="primary"
            size="sm"
            @click="updateVatInvoiceForm"
            >Lưu</b-button
          >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="$bvModal.hide('md-update-vat-invoice')"
            >Hủy</b-button
          >
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="md-upload-vat-invoice"
      hide-footer
      :title="'Tải chứng từ cho hoá đơn đỏ: #' + vatInvoiceUpload.refId"
    >
      <b-row class="mb-5">
        <div class="upload-file col-12">
          <vue-easy-lightbox
            escDisabled
            :visible="previewFile.visible"
            :imgs="previewFile.imgs"
            :index="previewFile.index"
            @hide="previewFile.visible = false"
          ></vue-easy-lightbox>

          <vue-easy-lightbox
            escDisabled
            :visible="previewAttachment.visible"
            :imgs="previewAttachment.imgs"
            :index="previewAttachment.index"
            @hide="previewAttachment.visible = false"
          ></vue-easy-lightbox>

          <div>
            <b-col
              class="preview-img-container mb-4"
              v-if="isLoadAttachmentSuccess"
            >
              <div>
                <!--File Previews-->
                <div class="d-flex">
                  <div
                    v-for="(item, index) in attachments"
                    :key="index"
                    class="img-container align-items-start"
                  >
                    <img
                      v-if="typecheck(item.fileName)"
                      :src="item.url"
                      class="preview-img"
                      width="80"
                      height="100"
                      :alt="item.originalName"
                      @click="showMultiplePreViewAttachment(index)"
                    />
                    <img
                      v-else
                      src="../../../assets/google-docs.svg"
                      class="preview-img"
                      width="80"
                      height="100"
                      :alt="item.originalName"
                      @click="showMultiplePreViewAttachment(index)"
                    />
                    <i
                      class="fas fa-times-circle text-danger close"
                      @click="deleteAttachment(item.id)"
                    >
                    </i>
                    <a :download="item.fileName" :href="item.url"
                      ><i class="fas fa-arrow-circle-down text-warning remove">
                      </i
                    ></a>
                  </div>
                </div>
              </div>
            </b-col>

            <!--UPLOAD-->
            <form enctype="multipart/form-data" novalidate class="mb-4">
              <b-button
                size="sm"
                variant="secondary"
                @click="openFileUpload()"
                class="font-weight-bolder mr-2"
              >
                <i class="fas fa-paperclip"></i>
                Đính kèm tệp
              </b-button>
              <b-button
                size="sm"
                variant="warning"
                @click="submitFileUpload()"
                class="font-weight-bolder"
                v-if="uploadedFiles.length"
              >
                <i class="fas fa-upload"></i>
                Tải tệp lên
              </b-button>
              <b-form-file
                v-model="fileAttach.files"
                ref="upload-files"
                id="upload-files"
                class="mb-2 d-none input-file"
                multiple
                :name="uploadFieldName"
                :disabled="isSaving"
                v-on:change="
                  filesChange($event.target.name, $event.target.files);
                  fileCount = $event.target.files.length;
                "
              />

              <p v-if="isSaving">Đang tải {{ fileCount }} tệp...</p>
              <div v-if="isFailed" class="d-flex">
                <small class="text-danger">Tải tệp thất bại.</small>
                <small>
                  <a href="javascript:void(0)" @click="reset()">Thử lại</a>
                </small>
                <pre>{{ uploadError }}</pre>
              </div>
            </form>

            <b-col class="preview-img-container mb-4" v-if="isSuccess">
              <div>
                <!--File Previews-->
                <div class="d-flex">
                  <div
                    v-for="(item, index) in uploadedFiles"
                    :key="index"
                    class="img-container align-items-start"
                  >
                    <img
                      v-if="typecheck(item.fileName)"
                      :src="item.url"
                      class="preview-img"
                      width="80"
                      height="100"
                      :alt="item.originalName"
                      @click="showMultiplePreViewFile(index)"
                    />
                    <img
                      v-else
                      src="../../../assets/google-docs.svg"
                      class="preview-img"
                      width="80"
                      height="100"
                      :alt="item.originalName"
                      @click="showMultiplePreViewFile(index)"
                    />

                    <i
                      class="fas fa-times-circle text-danger close"
                      @click="
                        removeFile(fileCount, uploadedFiles.indexOf(item))
                      "
                    >
                    </i>

                    <!-- <p style="max-width: 100px" class="text-center">
                      <b> {{ ftruncate(item.fileName) }}</b>
                    </p> -->
                  </div>
                </div>
              </div>
            </b-col>
            <!--SUCCESS-->
            <p v-if="isSuccess">
              <b>Tổng số {{ uploadedFiles.length }} tệp</b>
            </p>
            <!--FAILED-->
          </div>
        </div>
      </b-row>
    </b-modal>
    <VatInvoiceExcel :param="apiParams" />
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';
import {
  makeToastFaile,
  makeToastSuccess,
  convertPrice,
  selectAllOptions,
  removeSelectAllOptions,
  removeAccents,
} from './../../../utils/common';
import { TIME_OUT } from '../../../utils/constants';
import { cloneDeep, omitBy, isNil, find, assign, map } from 'lodash';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
import moment from 'moment';
import { UPLOAD_ENTITY } from '../../../utils/enum';
// import { checkPermissions } from '../../../utils/saveDataToLocal';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import VatInvoiceExcel from '../../components/vat-invoice/VatInvoiceExcel';
import axios from 'axios';
import { cmdUrl } from './../../../utils/apiUrl';
import JwtService from '@/core/services/jwt.service';
import VueEasyLightbox from 'vue-easy-lightbox';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;
export default {
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      apiParams: {
        storeIds: [],
        refId: null,
        fromDate: moment().subtract(15, 'days').format('DD/MM/YYYY'),
        toDate: moment().format('DD/MM/YYYY'),
        vatCompanyStatus: null,
        vatCompanyName: null,
        vatCompanyEmail: null,
        taxCode: null,
        vatCompanyNote: null,
        vatCompanyType: null,
      },
      title: '',
      vatInvoices: [],
      numberOfPage: 1,
      totalItems: 0,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      vatInvoiceFields: [
        {
          key: 'createdAtBill',
          label: 'Ngày tạo hoá đơn',
          thStyle: {
            width: '11%',
          },
          thClass: 'text-center',
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          thStyle: {
            width: '11%',
          },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'vatCompanyName',
          label: 'Tên công ty / cá nhân',
          thClass: 'text-center',
          thStyle: {
            width: '25%',
          },
        },
        {
          key: 'vatCompanyEmail',
          label: 'Email',
          thClass: 'text-center',
          thStyle: {
            width: '10%',
          },
        },
        {
          key: 'totalAmount',
          label: 'Số tiền',
          tdClass: 'text-right text-success',
          thClass: 'text-center',
          thStyle: {
            width: '10%',
          },
        },
        {
          key: 'vatCompanyCreatedAt',
          label: 'Ngày tạo hoá đơn đỏ',
          tdClass: 'text-center',
          thClass: 'text-center',
          thStyle: {
            width: '10%',
          },
        },
        {
          key: 'vatCompanyUpdatedAt',
          label: 'Ngày xử lý',
          tdClass: 'text-center',
          thClass: 'text-center',
          thStyle: {
            width: '10%',
          },
        },
        {
          key: 'vatCompanyNote',
          label: 'Ghi chú hoá đơn đỏ',
          thClass: 'text-center',
          thStyle: {
            width: '18%',
          },
        },
        {
          key: 'vatCompanyStatus',
          label: 'Trạng thái',
          tdClass: 'text-center ',
          thClass: 'text-center',
          thStyle: {
            width: '10%',
          },
        },
      ],
      onLoading: false,
      vatInvoiceForm: {
        refId: null,
        vatCompanyStatus: null,
        vatCompanyNote: null,
      },
      vatInvoiceFormMode: 'status',
      optionStores: [],
      filteredStore: [],
      previewFile: {
        visible: false,
        imgs: '',
        index: 0,
      },
      previewAttachment: {
        visible: false,
        imgs: '',
        index: 0,
      },
      attachments: null,
      isLoadAttachmentSuccess: false,
      fileAttach: {
        files: [],
      },
      uploadedFiles: [],
      fileCount: 0,
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      vatInvoiceUpload: {
        refId: null,
      },
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    datePicker,
    VatInvoiceExcel,
    VueEasyLightbox,
    BDropdownCustom
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },

  mounted() {
    this.title = 'Hoá đơn đỏ';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
    // if (isShow('VAT_INVOICE_UPDATE') || isShow('VAT_INVOICE_INSERT')) {
    //   this.vatInvoiceFields.push({
    //     key: 'actions',
    //     label: ' ',
    //     tdClass: 'text-center',
    //     thStyle: {
    //       width: '8%',
    //     },
    //   });
    // }
    this.vatInvoiceFields.push({
      key: 'actions',
      label: ' ',
      tdClass: 'text-center',
      thStyle: {
        width: '8%',
      },
    });
  },
  created() {
    this.fetchData();
    this.fetchStores();
    // this.loadAttachments();
  },
  methods: {
    convertPrice,
    // isShow(conditions) {
    //   return checkPermissions(conditions);
    // },
    editVatInvoiceForm(data, mode) {
      switch (mode) {
        case 'status': {
          this.vatInvoiceForm = {
            refId: data.refId,
            vatCompanyStatus: data.vatCompanyStatus || 1,
            vatCompanyUpdatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
          };
          break;
        }
        case 'note': {
          this.vatInvoiceForm = {
            refId: data.refId,
            vatCompanyNote: data.vatCompanyNote,
          };
          break;
        }
      }
      this.vatInvoiceForm = omitBy(this.vatInvoiceForm, isNil);
      this.vatInvoiceFormMode = mode;
      this.$bvModal.show('md-update-vat-invoice');
    },
    onInputed(textInput = '', type) {
      switch (type) {
        case 'Store': {
          this.searchStore(textInput);
          break;
        }

        default:
          break;
      }
    },
    onSelected(option, type) {
      switch (type) {
        case 'Store': {
          const { items, selectItems } = selectAllOptions(
            this.optionStores,
            this.apiParams.storeIds,
            option
          );
          this.filteredStore = items;
          this.apiParams.storeIds = selectItems;
          break;
        }

        default:
          break;
      }
    },
    onRemoved(option, type) {
      switch (type) {
        case 'Store': {
          const { items, selectItems } = removeSelectAllOptions(
            this.optionStores,
            this.apiParams.storeIds,
            option
          );

          this.filteredStore = items;
          this.apiParams.storeIds = selectItems;
          break;
        }
        default:
          break;
      }
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionStores);
      if (!textInput || !textInput.trim().length) {
        this.filteredStore = map(options, (obj) => {
          return assign(obj, find(this.filteredStore, { id: obj.id }));
        });
        // this.filteredStatus = options
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filteredStore, { id: obj.id }));
      });

      this.filteredStore = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    updateVatInvoiceForm: function () {
      const vatInvoiceFormUpdate = {
        ...cloneDeep(this.vatInvoiceForm),
        vatInvoiceFormMode: this.vatInvoiceFormMode,
      };

      return ApiService.put('/vat-invoices/', vatInvoiceFormUpdate)
        .then((response) => {
          if (!response.data.status) {
            makeToastFaile(response.data.message || 'Lỗi');
            return;
          }
          this.onFilter();
          makeToastSuccess(response.data.message);
          this.$bvModal.hide('md-update-vat-invoice');
        })
        .catch((error) => {
          this.$nprogress.done();
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi'
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
          return null;
        });
    },
    getParamFilters() {
      const DATE_FORMAT = 'DD/MM/YYYY';
      const DATE_DB_FORMAT = 'YYYY-MM-DD';
      let storeIds = this.apiParams.storeIds.map((item) => item.id);
      if (storeIds.includes(-1)) {
        storeIds = [];
      }
      const fromDate = this.apiParams.fromDate
        ? moment(this.apiParams.fromDate, DATE_FORMAT).format(DATE_DB_FORMAT)
        : '1991-01-01';

      const toDate = this.apiParams.toDate
        ? moment(this.apiParams.toDate, DATE_FORMAT).format(DATE_DB_FORMAT)
        : moment().format('YYYY-MM-DD');

      return {
        page: this.$route.query.page || 1,
        size: this.size || 10,
        refId: this.apiParams.refId ? this.apiParams.refId.trim() : null,
        vatCompanyStatus: this.apiParams.vatCompanyStatus,
        vatCompanyName: this.apiParams.vatCompanyName,
        vatCompanyEmail: this.apiParams.vatCompanyEmail,
        vatCompanyTaxCode: this.apiParams.vatCompanyTaxCode,
        vatCompanyNote: this.apiParams.vatCompanyNote,
        storeIds: storeIds,
        fromDate: fromDate,
        toDate: toDate,
        vatCompanyType: this.apiParams.vatCompanyType,
      };
    },
    downloadExcel() {
      this.$bvModal.show('modal-vat-invoice-excel');
    },
    onFilter() {
      this.fetchData();
    },
    fetchStores: async function () {
      const DEFAULT_COMPANY_ID = 3;
      ApiService.query(`stores/getStores`, {
        params: {
          companyId: DEFAULT_COMPANY_ID,
        },
      }).then((response) => {
        const stores = response.data.data;
        this.optionStores = [
          ...stores,
        ];
        this.filteredStore = cloneDeep(this.optionStores);
      });
    },
    fetchData: function () {
      if (this.onLoading) return;

      const params = this.getParamFilters();

      this.onLoading = true;
      ApiService.query('vat-invoices', { params }, { timeout: TIME_OUT })
        .then((response) => {
          const dataset = response.data.data;
          this.totalItems = dataset.total;
          this.numberOfPage = dataset.total_page;
          this.vatInvoices = dataset.data;
          this.onLoading = false;
        })
        .catch((error) => {
          this.onLoading = false;
          this.$nprogress.done();
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi'
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
        });
    },
    uploadVatInvoice: function (data) {
      this.vatInvoiceUpload = {
        refId: data.refId,
      };
      this.loadAttachments();
      this.$bvModal.show('md-upload-vat-invoice');
    },
    openFileUpload() {
      document.getElementById('upload-files').click();
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    //This is where we implement the storage service
    save(formData) {
      this.currentStatus = STATUS_SAVING;
      //Implement your own storage service when upload() is called.
      //For test purposes, I added a delay service
      this.upload(formData)
        .then((x) => {
          this.uploadedFiles = this.uploadedFiles.concat(x);
          this.currentStatus = STATUS_SUCCESS;
        })
        .catch((err) => {
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    upload(formData) {
      const photos = formData.getAll('photos');
      const promises = photos.map((x) =>
        this.getImage(x).then((img) => ({
          id: img,
          originalName: x.name,
          fileName: x.name,
          url: img,
        }))
      );
      return Promise.all(promises);
    },
    getImage(file) {
      return new Promise((resolve) => {
        const fReader = new FileReader();
        const img = document.createElement('img');

        fReader.onload = () => {
          img.src = fReader.result;
          resolve(this.getBase64Image(img));
        };

        fReader.readAsDataURL(file);
      });
    },
    getBase64Image(img) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = img.src;
      return dataURL;
    },
    //Returns true for image data types for others false, for preview purpose
    typecheck(oInput) {
      var _validFileExtensions = ['.jpg', '.jpeg', '.bmp', '.gif', '.png'];
      if (oInput.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            oInput
              .substr(
                oInput.length - sCurExtension.length,
                sCurExtension.length
              )
              .toLowerCase() == sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          return false;
        }
      }
      return true;
    },
    //Removes the selected file from the array.
    removeFile(fileCount, key) {
      fileCount = this.uploadedFiles.length;
      if (fileCount === 1) {
        this.uploadedFiles.splice(key, 1);
        this.reset();
      } else {
        this.uploadedFiles.splice(key, 1);
      }
    },
    //Func to truncate long filenames to short ones to display
    ftruncate(n) {
      // var len = 10;
      var ext = n.substring(n.lastIndexOf('.') + 1, n.length).toLowerCase();
      var filename = n.replace('.' + ext, '');
      // if (filename.length <= len) {
      //   return n;
      // }
      // filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');
      return filename + '.' + ext;
    },
    // Handle file changes
    filesChange(fieldName, fileList) {
      const formData = new FormData();
      if (!fileList.length) return;
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });
      // Save it to cloud
      this.save(formData);
    },
    submitFileUpload() {
      let formData = new FormData();
      for (const i of Object.keys(this.fileAttach.files)) {
        formData.append('fileUploads', this.fileAttach.files[i]);
      }
      this.currentStatus = STATUS_SAVING;
      const context = this;
      axios
        .post(
          `${cmdUrl.File.upload}?entityId=${this.vatInvoiceUpload.refId}&entity=${UPLOAD_ENTITY.BILLS_INVOICE}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${JwtService.getToken()}`,
            },
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            context.currentStatus = STATUS_SUCCESS;
          }
        })
        .catch(() => {
          context.currentStatus = STATUS_FAILED;
        });
    },
    deleteAttachment(id) {
      ApiService.delete(`${cmdUrl.File.root}/${id}`).then((deleteResponse) => {
        if (deleteResponse.status === 200) {
          if (deleteResponse.data.data === true) {
            const index = this.attachments.findIndex((item) => item.id === id);
            this.attachments.splice(index, 1);
          }
        }
      });
    },
    showMultiplePreViewAttachment(indexOfFile) {
      const files = this.attachments.map((file) => {
        return {
          src: file.url,
          title: file.fileName,
        };
      });
      this.previewAttachment.imgs = files;
      this.previewAttachment.index = indexOfFile;
      this.previewAttachment.visible = true;
    },
    loadAttachments: function () {
      this.isLoadAttachmentSuccess = false;
      ApiService.query('file', {
        params: {
          entity: UPLOAD_ENTITY.BILLS_INVOICE,
          entityId: this.vatInvoiceUpload.refId,
        },
      })
        .then(async (resp) => {
          if (resp.status === 200) {
            const attachments = resp.data.data.map((item) => ({
              id: item.id,
              fileName: item.fileName,
              originalName: item.fileName,
            }));

            this.attachments = await Promise.all(
              attachments.map(async ({ fileName, ...rest }) => {
                const resp = await axios.get(
                  `${cmdUrl.File.download}?filename=${fileName}&entity=bill-invoices`,
                  {
                    responseType: 'arraybuffer',
                    headers: {
                      Authorization: `Bearer ${JwtService.getToken()}`,
                    },
                  }
                );
                const contentType = resp.headers['content-type'];
                const base64 = `data:${contentType};base64,${Buffer.from(
                  resp.data
                ).toString('base64')}`;
                return {
                  ...rest,
                  url: base64,
                  fileName,
                };
              })
            );

            this.isLoadAttachmentSuccess = true;
          }
        })
        .catch(() => {
          this.isLoadAttachmentSuccess = false;
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.vat-invoice {
  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }

  .icon-size {
    font-size: 1rem;
  }

  table tbody td {
    vertical-align: middle;
  }

  input.form-control {
    border: 1px solid #ced4da;
  }

  .date-style {
    display: flex;
    align-items: center;
  }

  .pre-line {
    white-space: pre-line;
  }

  .multiselect__checkbox-name {
    display: block;
    overflow: hidden;
  }

  .multiselect__checkbox {
    vertical-align: middle;
    float: right;
  }

  .multiselect__tags {
    height: calc(1.35em + 1.1rem + 2px);
    display: block;
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    font-size: 0.925rem;
    min-height: initial;
    padding-top: 0.3rem;
  }

  .multiselect__option {
    padding: 5px;
    padding-top: 8px;
    min-height: 30px;
  }

  .multiselect__select {
    width: 3rem;
    height: 2.5rem;
    top: 0.1rem;
  }

  .multiselect__placeholder {
    color: #495057;
    padding-left: 0.2rem;
  }

  .multiselect__element {
    font-size: 0.925rem;
  }

  .multiselect__input {
    padding-top: 0.2rem;
    font-size: 0.925rem;
  }

  .multiselect__single {
    font-size: 0.925rem;
    line-height: 1.9;
    padding-left: 0.2rem;
    display: inline-block;
    max-width: 100%;
  }
}
.upload-file {
  .dropbox {
    margin: auto;
    width: 70%;
    background: #f8f8f8;
    border-radius: 20px;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px;
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    left: 0px;
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: #e8f5e9;
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
  .img-container {
    position: relative;
    display: inline-block;
    width: 80px;
    margin: 0px 10px;
  }
  .preview-img {
    max-width: 80px;
    padding: 10px;
    object-fit: contain;
    border: 1px solid #eeecff;
    border-radius: 5px;
  }
  .preview-img-container {
    border: 2px dashed #eeecff;
    padding: 2rem 1rem;
  }

  .preview-box {
    display: inline-block;
  }
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 15px;
  }
  .remove {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 15px;
  }
  .cancel {
    color: #545454;
    text-decoration: none;
  }
}
::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>
